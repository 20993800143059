import React from 'react';
import { RowContainer, Text } from '../styles/styledComponents';

export default function TextListItem({ children }) {
    return (
        <RowContainer style={ { alignSelf: "flex-start" } }>
            <div style={ { alignSelf: "flex-start", marginRight: 4 } }>
                <Text>-</Text>
            </div>
            <Text>{ children }</Text>
        </RowContainer>
    )
}
