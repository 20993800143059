import React, { useContext } from "react";
import ProfileCard from "./components/ProfileCard";
import { RowContainer } from "./styles/styledComponents";
import MenuCard from "./components/MenuCard";
import ContentCard from "./components/ContentCard";
import { AppDataContext } from "./contexts/AppDataContext";
import { Breakpoint } from "./utils/consts";
import BackToTop from "./components/BackToTop";

export default function Home() {

    const {
        breakpoint
    } = useContext(AppDataContext);

    const getStyle = () => {
        let rVal = {};
        switch (breakpoint) {
            case Breakpoint.Desktop:
            default:
                rVal = {
                    width: "88vw",
                    maxWidth: "1360px",
                    height: "68vh",
                    maxHeight: "550px",
                    flexWrap: "wrap",
                    margin: 0,
                    position: "relative",
                }
                break;
            case Breakpoint.Tablet:
                rVal = {
                    width: "68vw",
                    maxWidth: "700px",
                    height: "fit-content",
                    maxHeight: "fit-content",
                    flexWrap: "wrap",
                    margin: "16px 0",
                    position: "relative",
                }
                break;
            case Breakpoint.Mobile:
                rVal = {
                    width: "100vw",
                    maxWidth: "700px",
                    height: "fit-content",
                    maxHeight: "fit-content",
                    flexWrap: "wrap",
                    marginBottom: 16,
                    position: "relative",
                }
                break;
        }
        return rVal;
    }

    return (
        <RowContainer
            style={ getStyle() }
        >
            <MenuCard />
            <ProfileCard />
            <ContentCard />
            {breakpoint !== Breakpoint.Desktop && < BackToTop /> }
        </RowContainer>
    );
}
