import { useEffect, useState } from "react";

export default function useAccessibility() {

    // We enable an outline or not depending on how the button was interacted with.
    // If TAB was pressed, then we enable outlines for accessibility reasons.
    // If we click it, we disable the outline so we don't see the box after clicking.
    const [enableOutline, setEnableOutline] = useState(false);

    // On mount, we add a key down listener to the window, and use it to
    // determine if TAB was pressed and apply classes appropriately
    useEffect(() => {
        window.addEventListener("keydown", onWindowKeyDown);

        return (() => {
            window.removeEventListener("keydown", onWindowKeyDown);
        });
    }, []);

    const onWindowKeyDown = (e) => {
        const isTabEvent = e.keyCode === 9;
        if (isTabEvent) {
            setEnableOutline(true);
        }
    }

    return {
        enableOutline,
        setEnableOutline,
    };
}