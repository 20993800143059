import React from 'react';
import { DateRangeContainer } from '../styles/experienceStyles';

export default function DateRange({ from, to, present }) {
    return (
        <DateRangeContainer present={ present }>
            {from } - {to }
        </DateRangeContainer>
    )
}
