import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ConditionalWrapper as ConditionalIconRow } from "../utils/helpers";
import { ColumnContainer, Divider, Header, RowContainer, SVG } from '../styles/styledComponents';
import { Breakpoint, highlightColor } from '../utils/consts';
import { AppDataContext } from '../contexts/AppDataContext';

const Container = styled.div`
    margin-bottom: 16px;
    box-sizing: border-box;
    width: 100%;
`;

export default function ContentSection(props) {

    const {
        header,
        anchorID,
        children
    } = props;
    const iconSize = 24;
    const { breakpoint, anchorToID, setAnchorToID, contentScrollContainer } = useContext(AppDataContext);

    const containerRef = useRef(null);

    const onAnchorIDEffect = () => {
        if (anchorToID.length > 0) {
            if (anchorID === anchorToID) {
                if (breakpoint === Breakpoint.Desktop) {
                    // Scroll the scrollable container to the container position, offset by 32px
                    // which is the padding around the container
                    contentScrollContainer.current.scrollTop = containerRef.current.offsetTop - 32;
                }
                else {
                    // This is the height of the menu card, plus a little padding
                    const breakpointOffset = (breakpoint === Breakpoint.Mobile) ? 80 : 0;
                    const containerRect = containerRef.current.getBoundingClientRect();
                    const bodyRect = document.body.getBoundingClientRect();
                    const offset = containerRect.top - bodyRect.top;
                    window.scrollTo(0, offset - breakpointOffset);
                }
                setAnchorToID("");
            }
        }
    }
    useEffect(onAnchorIDEffect, [anchorToID]);

    return (
        <Container ref={ containerRef }>
            <ColumnContainer>
                <ConditionalIconRow
                    condition={ props.icon }
                    wrapper={ (children) =>
                        <RowContainer
                            style={ { alignSelf: "flex-start" } }
                        >
                            <SVG
                                fill={ highlightColor }
                                component={ <props.icon /> }
                                width={ iconSize }
                                height={ iconSize }
                                style={ { marginRight: 8 } }
                            />
                            { children }
                        </RowContainer>
                    }
                >
                    <Header
                        style={ {
                            alignSelf: "flex-start"
                        } }
                    >{ header }</Header>
                </ConditionalIconRow>
                <Divider style={ { marginBottom: 8 } } />
                { children }
            </ColumnContainer>
        </Container>
    )
}
