import { useEffect, useCallback } from "react";

export const useMediaQuery = (query, callback) => {
    const addListeners = useCallback(() => {
        // Allow for an array of queryStrings
        let queries = [];
        if (typeof query === "string") {
            queries.push(query);
        } else if (Array.isArray(query)) {
            queries = [...query];
        }

        for (let i = 0; i < queries.length; i++) {
            const queryString = queries[i];
            const mediaQuery = window.matchMedia(queryString);
            if (mediaQuery.matches) {
                callback(mediaQuery);
            }
            mediaQuery.addListener(() => {
                if (mediaQuery.matches) {
                    callback(mediaQuery);
                }
            });
        }
    }, [query, callback]);

    // On Mount
    useEffect(addListeners, []);
};


// const onBreakpointEffect = () => {
//     if (breakpoint.length > 0) {
//         switch (breakpoint) {
//             case Breakpoint.Desktop:
//
//                 break;
//             case Breakpoint.Tablet:
//                 
//                 break;
//             case Breakpoint.Mobile:
//                 
//                 break;
//             default:
//                 throw new Error();
//         }
//     }
// }
// useEffect(onBreakpointEffect, [breakpoint]);