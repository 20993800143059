import React, { useContext, useRef, useEffect, useState } from "react";
import Card from "./Card";
import {
    ColumnContainer,
    ClippedCircle,
    ProfilePicContainer,
    Image,
    Header,
    ClippedCircleBorder,
    FlexSpacer,
    Text,
    SVG,
    RowContainer,
    Link as LinkStyledComponent,
} from "../styles/styledComponents";
import TOSkylineFG from "../assets/images/TOSkylineForeground.png";
import TOSkylineMG from "../assets/images/TOSkylineMidground.png";
import TOSkylineBG from "../assets/images/TOSkylineBackground.png";
import TOSkylineFG2X from "../assets/images/TOSkylineForeground2X.png";
import TOSkylineMG2X from "../assets/images/TOSkylineMidground2X.png";
import TOSkylineBG2X from "../assets/images/TOSkylineBackground2X.png";
import { ReactComponent as DownloadIcon } from "../assets/images/download.svg";
import { ReactComponent as LinkedInIcon } from "../assets/images/linkedin.svg";
import { ReactComponent as TwitterIcon } from "../assets/images/twitter.svg";
import { AppDataContext } from "../contexts/AppDataContext";
import { highlightColor, Breakpoint, dullWhite } from "../utils/consts";
import Link from "./Link";
import { FGImage, MGImage, BGImage } from "../styles/skyline";

export default function ProfileCard({ width, height, zIndex }) {
    const {
        profilePic,
        breakpoint,
        contentScrollContainer,
    } = useContext(AppDataContext);
    const resumeURL = (process.env.NODE_ENV === "development") ? "#" : "./NicholasNgAFook_Resume.pdf";

    const [isDesktop, setIsDesktop] = useState(false);

    const fgElement = useRef(null);
    const mgElement = useRef(null);
    const bgElement = useRef(null);
    const fgMax = { x: 200, y: 15 };
    const mgMax = { x: 100, y: 10 };
    const bgMax = { x: 50, y: 5 };

    const clampValue = (value, min, max) => {
        return Math.min(Math.max(value, min), max);
    }

    const onMouseMove = (e) => {
        if (contentScrollContainer.current) {
            const rect = contentScrollContainer.current.getBoundingClientRect();
            let x = e.clientX - (rect.left + (rect.width / 2));
            let y = e.clientY - rect.top;
            // Convert x and y to a percentage based on delta moved from center of element
            x = clampValue(Math.floor((x / rect.width) * 200), -1000, 1000) / 100;
            y = clampValue(Math.floor((y / rect.height) * 100), 0, 100) / 100;

            if (fgElement.current && mgElement.current && bgElement.current) {
                fgElement.current.style.transform = `translate(${ x * fgMax.x }px, ${ y * fgMax.y }px)`;
                mgElement.current.style.transform = `translate(${ x * mgMax.x }px, ${ y * mgMax.y }px)`;
                bgElement.current.style.transform = `translate(${ x * bgMax.x }px, ${ y * bgMax.y }px)`;
            }
        }
    }

    const addMouseMoveEvent = () => {
        window.addEventListener("mousemove", onMouseMove);
    }

    const removeMouseMoveEvent = () => {
        window.removeEventListener("mousemove", onMouseMove);
    }

    const breakpointData = {
        [Breakpoint.Desktop]: {
            borderRadius: "4px",
            width: "28%",
            height: "100%",
            margin: "0",
            paddingTop: 16,
            zIndex: 1,
        },
        [Breakpoint.Tablet]: {
            borderRadius: "4px",
            width: "100%",
            height: "420px",
            maxHeight: "420px",
            margin: "0 0 8px 0",
            paddingTop: 16,
            zIndex: 1,
        },
        [Breakpoint.Mobile]: {
            borderRadius: "0 0px 4px 4px",
            width: "94%",
            height: "48vh",
            maxHeight: "380px",
            margin: "64px 0 0 0",
            paddingTop: 16,
            zIndex: 1,
        }
    }

    const onBreakpointEffect = () => {
        if (breakpoint.length > 0) {
            switch (breakpoint) {
                case Breakpoint.Desktop:
                    setIsDesktop(true);
                    addMouseMoveEvent();
                    break;
                case Breakpoint.Tablet:
                case Breakpoint.Mobile:
                    setIsDesktop(false);
                    removeMouseMoveEvent();
                    break;
                default:
                    throw new Error();
            }
        }
    }
    useEffect(onBreakpointEffect, [breakpoint]);

    const onMount = () => {
        setIsDesktop(breakpoint === Breakpoint.Desktop);
        if (breakpoint === Breakpoint.Desktop) {
            addMouseMoveEvent();
        }

        return (() => {
            removeMouseMoveEvent();
        });
    }
    useEffect(onMount, []);

    return (
        <Card
            name="Profile Card"
            breakpointData={ breakpointData }
            width={ width }
            zIndex={ zIndex }
            nopadding
        >
            <div
                style={ {
                    overflow: "hidden",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                } }
            />
            <ColumnContainer
                style={ {
                    position: "relative",
                    overflow: "hidden",
                } }
            >
                <FGImage
                    name="FGSkyline"
                    desktop={ isDesktop }
                    ref={ fgElement }
                    position="bottom"
                    url={ isDesktop ? TOSkylineFG : TOSkylineFG2X }
                />
                <MGImage
                    name="MGSkyline"
                    desktop={ isDesktop }
                    ref={ mgElement }
                    position="bottom"
                    url={ isDesktop ? TOSkylineMG : TOSkylineMG2X }
                />
                <BGImage
                    name="BGSkyline"
                    desktop={ isDesktop }
                    ref={ bgElement }
                    position="bottom"
                    url={ isDesktop ? TOSkylineBG : TOSkylineBG2X }
                />
                <ColumnContainer>
                    <ProfilePicContainer width={ "96px" } height={ "96px" }>
                        <ClippedCircleBorder />
                        <ClippedCircle>
                            <Image src={ profilePic } alt="Profile Pic" />
                        </ClippedCircle>
                    </ProfilePicContainer>
                    <Header>Nicholas Ng-A-Fook</Header>
                    <Text color={ highlightColor }>
                        Full Stack Javascript Developer
                    </Text>
                    <RowContainer style={ { marginTop: 8 } }>
                        <Link href="https://twitter.com/nngafook" target="_blank" style={ { marginRight: 16 } }>
                            <SVG fill={ dullWhite } stroke={ highlightColor } container={ LinkStyledComponent } component={ <TwitterIcon /> } width={ 24 } height={ 24 } />
                        </Link>
                        <Link href="https://www.linkedin.com/in/nngafook/" target="_blank">
                            <SVG fill={ dullWhite } stroke={ highlightColor } container={ LinkStyledComponent } component={ <LinkedInIcon /> } width={ 24 } height={ 24 } />
                        </Link>
                    </RowContainer>

                    <Link
                        download={ process.env.NODE_ENV !== "development" }
                        href={ resumeURL }
                        style={ { marginTop: 0, padding: "0 0 0 4px" } }
                    >
                        <Text style={ { marginRight: 8 } }>Download Resume</Text>
                        <SVG container={ LinkStyledComponent } component={ <DownloadIcon /> } width={ 32 } height={ 32 } />
                    </Link>


                </ColumnContainer>
                <FlexSpacer percent={ 35 } />
            </ColumnContainer>
        </Card>
    );
}
