import styled from "styled-components";
import { highlightColor, animScaleSize, Breakpoint } from '../utils/consts';

export const MenuItemContainer = styled.button`
    background: none;
    border: none;
    display: flex;
    flex-direction: ${ (props) => props.breakpoint === Breakpoint.Mobile ? "row" : "column" };
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 8px 0 0 0;
    text-align: center;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    cursor: pointer;
    transition: 0.3s;
    outline: none;
    padding: 0;
    position: relative;

    &:not(:last-child):after {
        content: "";
        position: absolute;
        padding: 1px;
        box-sizing: border-box;
        ${ (props) => props.breakpoint === Breakpoint.Mobile && `
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 0;
            height: 70%;
            background:
            ${ props.centered ?
            `linear-gradient(180deg, ${ highlightColor }00, ${ highlightColor }66, ${ highlightColor }00);` :
            `linear-gradient(180deg, ${ highlightColor }66, ${ highlightColor }00);
            ` }
        `};
        ${ (props) => props.breakpoint !== Breakpoint.Mobile && `
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 84%;
            height: 0;
            background:
            ${ props.centered ?
            `linear-gradient(to right, ${ highlightColor }00, ${ highlightColor }66, ${ highlightColor }00);` :
            `linear-gradient(to right, ${ highlightColor }66, ${ highlightColor }00);
            ` }
        `};
    }

    &:hover {
        color: ${ highlightColor };
    }
    &:active {
        outline: none;
        transform: scale(${ animScaleSize });
    }
    &:last-child {
        // margin: 8px 0;
    }
    &:focus {
        ${ (props) => props.enableFocus ? `
            transition: 0s;
            border-radius: 3px;
            border: solid 1px;
            color: ${ highlightColor };
        ` : `
            border: none !important;
            outline: none !important;
        `}
    }
`;