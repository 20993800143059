import React, { useContext } from 'react';
import Card from './Card';
import MenuItem from './MenuItem';
import { ReactComponent as AboutIcon } from '../assets/images/about.svg';
import { ReactComponent as ContactIcon } from '../assets/images/contact.svg';
import { ReactComponent as EducationIcon } from '../assets/images/education.svg';
import { ReactComponent as ExperienceIcon } from '../assets/images/experience.svg';
import { ReactComponent as SkillsIcon } from '../assets/images/skills.svg';
import { AbsoluteMenuWrapper, ColumnContainer, RowContainer } from '../styles/styledComponents';
import { Breakpoint } from '../utils/consts';
import { ConditionalWrapper as AbsoluteContainer } from '../utils/helpers';
import { ConditionalWrapper as ColumnConditional } from '../utils/helpers';
import { ConditionalWrapper as RowConditional } from '../utils/helpers';
import { AppDataContext } from '../contexts/AppDataContext';

export default function MenuCard() {

    const { breakpoint } = useContext(AppDataContext);

    const breakpointData = {
        [Breakpoint.Desktop]: {
            borderRadius: "4px",
            width: "80px",
            zIndex: 2,
        },
        [Breakpoint.Tablet]: {
            borderRadius: "4px",
            width: "80px",
            maxHeight: 320,
            margin: 0,
            position: "fixed",
            transform: "translate(-96%, 5%)",
            zIndex: 2,
            overflowY: "auto",
        },
        [Breakpoint.Mobile]: {
            borderRadius: "0px",
            width: "100%",
            height: 64,
            margin: 0,
            position: "fixed",
            zIndex: 2,
            overflowY: "auto",
        },
    }

    return (
        <AbsoluteContainer
            condition={ breakpoint !== Breakpoint.Desktop }
            wrapper={ (children) =>
                <AbsoluteMenuWrapper
                    name="Menu Wrapper"
                >
                    { children }
                </AbsoluteMenuWrapper>
            }
        >
            <Card
                nopadding
                name="Menu Card"
                breakpointData={ breakpointData }
            >

                <ColumnConditional
                    condition={ breakpoint !== Breakpoint.Mobile }
                    wrapper={ (children) =>
                        <ColumnContainer
                            name="Menu Column Wrapper"
                            style={ {
                                padding: "8px 0"
                            } }
                        >
                            { children }
                        </ColumnContainer>
                    }
                >
                    <RowConditional
                        condition={ breakpoint === Breakpoint.Mobile }
                        wrapper={ (children) =>
                            <RowContainer
                                name="Menu Row Wrapper"
                                style={ {
                                    flex: "1 1 0",
                                } }
                            >
                                { children }
                            </RowContainer>
                        }
                    >
                        <MenuItem label={ "ABOUT" } icon={ AboutIcon } iconSize={ 16 } to="about" />
                        <MenuItem label={ "SKILLS" } icon={ SkillsIcon } iconSize={ 16 } to="skills" />
                        <MenuItem label={ "EXPERIENCE" } icon={ ExperienceIcon } iconSize={ 16 } to="experience" />
                        <MenuItem label={ "EDUCATION" } icon={ EducationIcon } iconSize={ 24 } to="education" />
                        <MenuItem label={ "CONTACT" } icon={ ContactIcon } iconSize={ 16 } to="contact" noDivider />
                    </RowConditional>
                </ColumnConditional>
            </Card >
        </AbsoluteContainer>
    )
}
