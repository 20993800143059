import React, { useContext } from "react";
import styled from "styled-components";
import SimpleBarReact from "simplebar-react";
import { ConditionalWrapper as ConditionalScrollable } from "../utils/helpers";
import "simplebar/src/simplebar.css";
import { AppDataContext } from "../contexts/AppDataContext";

const Container = styled.div`
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
    background-color: #31313a;
    position: relative;
    overflow:hidden;

    &:last-child {
        margin-bottom: 0 !important;
    }

    ${ (props) => props.scrollable && `
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 20px;
            width: 100%;
            height: 40px;
            background-image: linear-gradient(to bottom, #31313a 20%, #31313a00 100%);
            z-index: 1000;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 20px;
            width: 100%;
            height: 40px;
            background-image: linear-gradient(to top, #31313a 20%, #31313a00 100%);
            z-index: 1000;
        }
    `}
`;

export default function Card(props) {

    const {
        name,
        children,
        width,
        height,
        zIndex,
        scrollable,
        borderRadius,

        breakpointData,

        padding,
        nopadding,
    } = props;

    const { contentScrollContainer, breakpoint } = useContext(AppDataContext);

    return (
        <Container
            name={ name }
            width={ width }
            height={ height }
            zIndex={ zIndex }
            scrollable={ scrollable }
            borderRadius={ borderRadius }
            style={ { ...breakpointData[breakpoint] } }
        >

            <ConditionalScrollable
                condition={ scrollable }
                wrapper={ children =>
                    <SimpleBarReact
                        style={ { maxHeight: "100%" } }
                        scrollableNodeProps={ { ref: contentScrollContainer } }
                    >
                        { children }
                    </SimpleBarReact>
                }
            >
                <div
                    style={ {
                        ...(!nopadding &&
                            (padding
                                ? { ...{ padding: padding } }
                                : { ...{ padding: 32 } })),
                        height: "100%",
                        position: "relative",
                        boxSizing: "border-box",
                        display: "flex",
                    } }
                >
                    { children }
                </div>
            </ConditionalScrollable>
        </Container>
    );
}
