import React, { useContext } from 'react';
import { CompanyName, ExperienceHeader, SkillText } from '../styles/experienceStyles';
import {
    ColumnContainer,
    TwoColumnContainer,
    OneOfTwoColumn,
    RowContainer,
    Text,
} from '../styles/styledComponents';
import { ReactComponent as AboutIcon } from '../assets/images/about.svg';
import { ReactComponent as EducationIcon } from '../assets/images/education.svg';
import { ReactComponent as ExperienceIcon } from '../assets/images/experience.svg';
import { ReactComponent as SkillsIcon } from '../assets/images/skills.svg';
import Card from './Card';
import ContactSection from './ContactSection';
import ContentSection from './ContentSection';
import DateRange from './DateRange';
import TextListItem from './TextListItem';
import Link from './Link';
import { Breakpoint } from '../utils/consts';
import { AppDataContext } from '../contexts/AppDataContext';

export default function ContentCard() {

    const { breakpoint } = useContext(AppDataContext);
    const isMobile = breakpoint === Breakpoint.Mobile;
    // const getAge = () => {
    //     const dob = new Date(1984, 2, 15);
    //     const msDifference = Date.now() - dob.getTime();
    //     const ageDate = new Date(msDifference);

    //     return Math.abs(ageDate.getUTCFullYear() - 1970);
    // }

    const breakpointData = {
        [Breakpoint.Desktop]: {
            borderRadius: "0 4px 4px 0",
            width: "60%",
            height: "92%",
            zIndex: 0,
        },
        [Breakpoint.Tablet]: {
            borderRadius: "4px",
            width: "100%",
            height: "100%",
            margin: "0 0 16px 0",
            zIndex: 0,
        },
        [Breakpoint.Mobile]: {
            borderRadius: "0 0px 4px 4px",
            width: "90%",
            height: "100%",
            zIndex: 0,
        }
    }

    return (
        <Card
            scrollable
            name="Content Card"
            breakpointData={ breakpointData }
        >
            <ColumnContainer
                id="foo_bar"
                name="Card Column Container"
                style={ {
                    placeContent: "flex-start",
                    alignItems: "flex-start",
                } }
            >

                <ContentSection
                    header="About Me"
                    icon={ AboutIcon }
                    anchorID="about"
                >
                    <Text>
                        { `Passionate and experienced developer with several years of Web, Game & Application development in an Agile environment, and leading various projects. Dedicated, hardworking, and committed to becoming a dependable and valuable team member. Constantly learning, constantly growing.` }
                    </Text>
                    <TwoColumnContainer
                        style={ {
                            width: "100%",
                            alignSelf: "flex-start",
                            justifyContent: "space-between",
                            marginTop: 4
                        } }
                    >
                        <OneOfTwoColumn mobile={ isMobile } style={ { flexDirection: "row", marginBottom: 0 } }>
                            <Text style={ { color: "white", fontWeight: "bold" } }>Email:&nbsp;</Text>
                            <Link href="mailto: dev.nngafook@gmail.com" style={ { marginRight: 42, padding: "0 4px" } }>dev.nngafook@gmail.com</Link>
                        </OneOfTwoColumn>
                        <OneOfTwoColumn mobile={ isMobile } style={ { flexDirection: "row", justifyContent: isMobile ? "flex-start" : "flex- end", marginBottom: 0 } }>
                            <Text style={ { color: "white", fontWeight: "bold" } }>Location:&nbsp;</Text>
                            <Text>North York, ON, Canada</Text>
                        </OneOfTwoColumn>
                    </TwoColumnContainer>
                </ContentSection>

                <ContentSection
                    header="Skills"
                    icon={ SkillsIcon }
                    anchorID="skills"
                >
                    <RowContainer
                        style={ {
                            width: "100%",
                            alignSelf: "flex-start",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                        } }
                    >
                        <SkillText mobile={ isMobile }>ReactJS</SkillText>
                        <SkillText mobile={ isMobile }>NodeJS</SkillText>
                        <SkillText mobile={ isMobile }>Shopify/Liquid</SkillText>
                        <SkillText mobile={ isMobile }>JavaScript</SkillText>
                        <SkillText mobile={ isMobile }>Ruby on Rails</SkillText>
                        <SkillText mobile={ isMobile }>HTML & CSS</SkillText>
                        <SkillText mobile={ isMobile }>GraphQL</SkillText>
                        <SkillText mobile={ isMobile }>PostgreSQL</SkillText>
                        <SkillText mobile={ isMobile }>MongoDB</SkillText>
                        <SkillText mobile={ isMobile }>SVN/Git</SkillText>
                        <SkillText mobile={ isMobile }>C#</SkillText>
                        <SkillText mobile={ isMobile }>Unity3D</SkillText>
                        <SkillText mobile={ isMobile }>Agile Development</SkillText>
                    </RowContainer>
                </ContentSection>

                <ContentSection
                    header="Experience"
                    icon={ ExperienceIcon }
                    anchorID="experience"
                >
                    <TwoColumnContainer>
                        <OneOfTwoColumn mobile={ isMobile }>
                            <DateRange from="May 2021" to="Present" present />
                            <ExperienceHeader>Intermmediate Software Developer</ExperienceHeader>
                            <CompanyName>HiMama Inc</CompanyName>
                            <TextListItem>Support, build and maintain core features of our childcare mobile application.</TextListItem>
                        </OneOfTwoColumn>
                        
                        <OneOfTwoColumn mobile={ isMobile }>
                            <DateRange from="January 2021" to="May 2021" />
                            <ExperienceHeader>eCommerce Software Developer</ExperienceHeader>
                            <CompanyName>LFL Group</CompanyName>
                            <TextListItem>Maintain & enhance multiple banner/brand stores across the company.</TextListItem>
                            <TextListItem>Implement and update new procedures and technologies within Digital to help grow the company's digital presence.</TextListItem>
                        </OneOfTwoColumn>

                        <OneOfTwoColumn mobile={ isMobile }>
                            <DateRange from="August 2020" to="May 2021" />
                            <ExperienceHeader>Full Stack Developer (Contractor/Part-Time)</ExperienceHeader>
                            <CompanyName>Stack Five</CompanyName>
                            <TextListItem>Front end development on a React / NodeJS application hosted on AWS / EC2 servers.</TextListItem>
                        </OneOfTwoColumn>

                        <OneOfTwoColumn mobile={ isMobile }>
                            <DateRange from="October 2017" to="August 2020" />
                            <ExperienceHeader>Lead Shopify Web Developer</ExperienceHeader>
                            <CompanyName>World Vision Canada</CompanyName>
                            <TextListItem>Enhanced & upgraded a customized Shopify theme based on business requirements and requests.</TextListItem>
                            <TextListItem>Built a Shopify App in React/Electron to synchronize data between multiple Shopify stores, leading to increased efficiencies with inventory management.</TextListItem>
                            <TextListItem>Improved processes and built components within our Shopify theme to enable effective customization, leading to faster content updates and changes.</TextListItem>
                            <TextListItem>Built a React live chat client used across different World Vision Canada web properties.</TextListItem>
                        </OneOfTwoColumn>

                        <OneOfTwoColumn mobile={ isMobile }>
                            <DateRange from="September 2015" to="March 2016" />
                            <ExperienceHeader>Lead Video Game Developer</ExperienceHeader>
                            <CompanyName>Yellow Bear Studios</CompanyName>
                            <TextListItem>Built a mobile game in Unity & C#.</TextListItem>
                            <TextListItem>Interfaced with a backend asset pipeline with Gamesparks.</TextListItem>
                        </OneOfTwoColumn>

                        <OneOfTwoColumn mobile={ isMobile }>
                            <DateRange from="August 2013" to="August 2015" />
                            <ExperienceHeader>Flash Scripter</ExperienceHeader>
                            <CompanyName>Gameloft</CompanyName>
                            <TextListItem>Built UI components for a mobile game using Adobe Flash and AS3.</TextListItem>
                        </OneOfTwoColumn>

                    </TwoColumnContainer>
                </ContentSection>

                <ContentSection
                    header="Education"
                    icon={ EducationIcon }
                    anchorID="education"
                >
                    <TwoColumnContainer>
                        <OneOfTwoColumn mobile={ isMobile }>
                            <DateRange from="October 2010" to="August 2012" />
                            <ExperienceHeader>TriOS College</ExperienceHeader>
                            <CompanyName>Toronto</CompanyName>
                            <Text>Degree in Video Game Design & Development.</Text>
                        </OneOfTwoColumn>
                    </TwoColumnContainer>
                </ContentSection>

                <ContactSection />

            </ColumnContainer>
        </Card >
    )
}
