import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";
import { AppDataProvider } from "./contexts/AppDataContext";

ReactDOM.render(
    <React.StrictMode>
        <AppDataProvider>
            <Router>
                <Switch>
                    <App />
                </Switch>
            </Router>
        </AppDataProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
