import React, { useContext } from "react";
import { AppDataContext } from "../contexts/AppDataContext";
import { MenuItemContainer } from "../styles/menuItemStyles";
import { ColumnContainer, SVG, Text } from "../styles/styledComponents";
import { Breakpoint } from "../utils/consts";

export default function MenuItem(props) {
    const { label, iconSize, to } = props;
    const {
        breakpoint,
        setAnchorToID,
        enableOutline, setEnableOutline,
    } = useContext(AppDataContext);

    const onClicked = () => {
        setEnableOutline(false);
        setAnchorToID(to);
    }

    const getContainerStyle = () => {
        return breakpoint === Breakpoint.Mobile ? {
            margin: 0,
        } : {}
    }

    return (
        <MenuItemContainer
            centered
            breakpoint={ breakpoint }
            onClick={ onClicked }
            enableFocus={ enableOutline }
            style={ getContainerStyle() }
        >
            <ColumnContainer
                style={ {
                    width: "fit-content",
                    height: "fit-content"
                } }
            >
                <div
                    style={ {
                        width: 20,
                        height: 20,
                        marginBottom: 4,
                        display: "flex",
                        placeContent: "center",
                        alignItems: "center",
                    } }
                >
                    <SVG
                        container={ MenuItemContainer }
                        component={ <props.icon /> }
                        width={ iconSize ? iconSize : 24 }
                        height={ iconSize ? iconSize : 24 }
                        style={ {
                            marginBottom: (breakpoint === Breakpoint.Mobile) ? 0 : 4
                        } }
                    />
                </div>
                <Text noSelect small style={ { marginBottom: (breakpoint === Breakpoint.Mobile) ? 0 : 8 } }>{ label }</Text>
            </ColumnContainer>
        </MenuItemContainer>
    );
}
