import React, { useEffect, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AppDataContext } from "./contexts/AppDataContext";
// import ResumeViewer from "./components/ResumeViewer";
import Home from "./Home";
import { Breakpoint } from "./utils/consts";
import { useMediaQuery } from "./hooks/useMediaQuery";

function App() {
    const {
        setBreakpoint,
        setPDF, setProfilePic
    } = useContext(AppDataContext);

    const getRoutes = () => {
        return (
            <React.Fragment>
                <Route path="/" exact component={ Home } />
                <Route path='*' exact>
                    <Redirect to="/" />
                </Route>
            </React.Fragment>
        );
    }

    useMediaQuery(
        [Breakpoint.Desktop, Breakpoint.Tablet, Breakpoint.Mobile],
        (mediaQuery) => {
            const breakpoint = mediaQuery.media;
            setBreakpoint(breakpoint);
            // Handle body style updates
            if (breakpoint.length > 0) {
                switch (breakpoint) {
                    case Breakpoint.Desktop:
                    default:
                        document.body.style.justifyContent = "center";
                        break;
                    case Breakpoint.Tablet:
                    case Breakpoint.Mobile:
                        document.body.style.justifyContent = "flex-start";
                        break;
                }
            }
        }
    );

    const onMount = () => {
        const getResume = async () => {
            await fetch("/NicholasNgAFook_Resume.pdf").then((response) => {
                setPDF(response.url);
            });
        };
        getResume();

        const getProfilePic = async () => {
            await fetch("/profilepic.jpg").then((response) => {
                setProfilePic(response.url);
            });
        };
        getProfilePic();
    };
    useEffect(onMount, []);

    return (getRoutes());
}

export default App;
