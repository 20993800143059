import React, { useContext } from "react";
import { AppDataContext } from "../contexts/AppDataContext";
import {
    TextButton as TextButtonStyledComponent,
} from "../styles/styledComponents";

export default function Link({ onClick, style, children }) {

    const {
        enableOutline, setEnableOutline
    } = useContext(AppDataContext);

    const onClicked = () => {
        setEnableOutline(false);
        onClick();
    }

    return (
        <TextButtonStyledComponent
            style={ style }
            enableFocus={ enableOutline }
            onClick={ onClicked }
        >
            { children }
        </TextButtonStyledComponent>
    )
}
