import React, { useContext } from "react";
import { AppDataContext } from "../contexts/AppDataContext";
import {
    Link as LinkStyledComponent,
} from "../styles/styledComponents";

export default function Link({ download, href, target, style, children }) {
    const {
        enableOutline, setEnableOutline
    } = useContext(AppDataContext);

    const onClicked = () => {
        setEnableOutline(false);
    }

    return (
        <LinkStyledComponent
            download={ download }
            href={ href }
            target={ target }
            style={ style }
            enableFocus={ enableOutline }
            onClick={ onClicked }
        >
            { children }
        </LinkStyledComponent>
    )
}
