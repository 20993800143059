import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ToTop } from '../assets/images/totop.svg';
import { AppDataContext } from "../contexts/AppDataContext";
import { SVG, ToTopButton } from "../styles/styledComponents";
import { bodyFontColor } from "../utils/consts";

export default function BackToTop() {

    const { breakpoint } = useContext(AppDataContext);
    const [visible, setVisible] = useState(false);

    const onClick = () => {
        window.scrollTo(0, 0);
    }

    const onWindowScroll = () => {
        if (window.scrollY >= 200) {
            setVisible(true);
        }
        else if (window.scrollY < 200) {
            setVisible(false);
        }
    }

    const onMount = () => {
        window.addEventListener("scroll", onWindowScroll);

        return (() => {
            window.removeEventListener("scroll", onWindowScroll);
        });
    }
    useEffect(onMount, []);

    return (
        <ToTopButton
            visible={ visible }
            breakpoint={ breakpoint }
            onClick={ onClick }
        >
            <SVG
                fill={ bodyFontColor }
                container={ ToTopButton }
                component={ <ToTop /> }
                width={ 16 }
                height={ 16 }
                style={ {
                    marginBottom: 0
                } }
            />
        </ToTopButton>
    )
}
