import React, { useContext, useState } from 'react';
import {
    InputField,
    TwoColumnContainer,
    OneOfTwoColumn,
    TextArea,
    Text,
    TextButton as TextButtonStyledComponent,
    SVG, Loader
} from '../styles/styledComponents';
import { ReactComponent as EmailIcon } from "../assets/images/email.svg";
import { ReactComponent as ContactIcon } from '../assets/images/contact.svg';
import { ReactComponent as LoadingIcon } from '../assets/images/loader.svg';
import ContentSection from './ContentSection';
import TextButton from './TextButton';
import { AppDataContext } from '../contexts/AppDataContext';
import { Breakpoint, errorRed, highlightColor } from '../utils/consts';

export default function ContactSection() {

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sending, setSending] = useState(false);
    const [sentConfirmation, setSentConfirmation] = useState(false);
    const [sentFailure, setSentFailure] = useState(false);

    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [messageError, setMessageError] = useState("");

    const { breakpoint } = useContext(AppDataContext);
    const isMobile = breakpoint === Breakpoint.Mobile;

    const onSendMessage = async () => {
        const nameValid = isNameValid();
        const emailValid = isEmailValid();
        const messageValid = isMessageValid();

        if (nameValid && emailValid && messageValid) {
            setSending(true);
            if (process.env.NODE_ENV !== "development") {
                const updateRequest = new FormData();
                updateRequest.append('name', fullName);
                updateRequest.append('email', email);
                updateRequest.append('message', message);

                await fetch('./api/contact/mail.php', {
                    method: 'POST',
                    body: updateRequest
                })
                    .then(response => response.json())
                    .then(data => {
                        const { success } = data;

                        setSending(false);
                        if (success) {
                            showSentConfirmation();
                            clearFields();
                        }
                        else {
                            showSentFailure();
                        }
                    })
                    .catch(error => console.log(error));
            }
            else {
                setTimeout(() => {
                    setSending(false);
                    showSentConfirmation();
                }, 2000);
            }
        }
    }

    const clearFields = () => {
        setFullName("");
        setEmail("");
        setMessage("");
    }

    const showSentConfirmation = () => {
        setSentConfirmation(true);
        setTimeout(() => {
            setSentConfirmation(false);
        }, 2000);
    }

    const showSentFailure = () => {
        setSentFailure(true);
        setTimeout(() => {
            setSentFailure(false);
        }, 2000);
    }

    const isNameValid = () => {
        if (fullName.length > 0) {
            setNameError("");
            return true;
        }
        else {
            setNameError("Name required");
            return false;
        }
    }
    const isEmailValid = () => {
        if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/.test(email)) {
            setEmailError("");
            return true;
        }
        else {
            setEmailError("Invalid email address");
            return false;
        }
    }
    const isMessageValid = () => {
        if (message.length > 0) {
            setMessageError("");
            return true;
        }
        else {
            setMessageError("Message required");
            return false;
        }
    }

    const renderSendButton = () => {
        let returnElement = null;

        if (sending) {
            returnElement = <div
                style={ {
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "flex-end",
                    marginBottom: 16,
                } }
            >
                Sending
                <Loader>
                    <SVG fill={ highlightColor } component={ <LoadingIcon /> } width={ 24 } height={ 24 } style={ { marginBottom: 0 } } />
                </Loader>
            </div>
        }
        else if (sentConfirmation) {
            returnElement = <Text
                color={ highlightColor }
                style={ {
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "flex-end",
                    marginBottom: 16,
                } }
            >
                Message Sent!
            </Text>
        }
        else if (sentFailure) {
            returnElement = <Text
                color={ errorRed }
                style={ {
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "flex-end",
                    marginBottom: 16,
                } }
            >
                Error sending message. Try again.
            </Text>
        }
        else {
            returnElement = <TextButton onClick={ onSendMessage } style={ { marginTop: 0, marginBottom: 16, padding: "0 4px", alignSelf: "flex-end" } }>
                <Text noSelect style={ { marginRight: 8 } }>Send Message</Text>
                <SVG container={ TextButtonStyledComponent } component={ <EmailIcon /> } width={ 24 } height={ 24 } />
            </TextButton>
        }

        return returnElement;
    }

    return (
        <ContentSection
            header="Contact Form"
            icon={ ContactIcon }
            anchorID="contact"
        >
            <TwoColumnContainer>
                <OneOfTwoColumn mobile={ isMobile }>
                    <InputField
                        disabled={ sending }
                        type="input"
                        id="fullName"
                        placeholder="Full Name"
                        value={ fullName }
                        onChange={ (e) => {
                            setFullName(e.target.value);
                        } }
                    />
                    <Text
                        color={ errorRed }
                        style={ {
                            display: nameError.length > 0 ? "block" : "none"
                        } }
                    >{ nameError }</Text>
                </OneOfTwoColumn>
                <OneOfTwoColumn mobile={ isMobile }>
                    <InputField
                        disabled={ sending }
                        type="input"
                        id="email"
                        placeholder="Email"
                        value={ email }
                        onChange={ (e) => {
                            setEmail(e.target.value);
                        } }
                    />
                    <Text
                        color={ errorRed }
                        style={ {
                            display: emailError.length > 0 ? "block" : "none"
                        } }
                    >{ emailError }</Text>
                </OneOfTwoColumn>
            </TwoColumnContainer>
            <TextArea
                disabled={ sending }
                id="message"
                placeholder="Enter Message"
                value={ message }
                onChange={ (e) => {
                    setMessage(e.target.value);
                } }
            />
            <Text
                color={ errorRed }
                style={ {
                    alignSelf: "flex-start",
                    display: messageError.length > 0 ? "block" : "none"
                } }
            >{ messageError }</Text>

            { renderSendButton() }
        </ContentSection >
    )
}
