import styled from "styled-components";
import { highlightColor, dullWhite } from "../utils/consts";
import { Header, RowContainer, Text } from "./styledComponents";

export const SkillText = styled(Text)`
    flex-grow: ${ (props) => props.mobile ? 2 : 3 };
    width: ${ (props) => props.mobile ? `50%` : `33%` };
`;

export const CompanyName = styled(Text)`
    color: ${ highlightColor };
    font-weight: bold;
`;

export const ExperienceHeader = styled(Header)`
    font-size: 16px;
`;

export const DateRangeContainer = styled(RowContainer)`
    align-self: flex-start;
    border-radius: 3px;
    border: solid 1px;
    padding: 2px 4px;
    margin: 4px 0;
    color: ${ (props) => props.present ? `${ highlightColor }` : `${ dullWhite }` }
`;