import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Breakpoint, highlightColor, bodyFontColor, dullWhite, animScaleSize } from "../utils/consts";

export const Header = styled.h1`
    font-size: 20px;
    color: white;
    margin: 0;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
`;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    width: 100%;
    height: 100%;
`;

export const FlexSpacer = styled.div`
    flex: 1 1 ${ (props) => props.percent }%;
`;

export const SVG = styled(({ component, ...props }) =>
    React.cloneElement(component, props)
)`
    fill: ${ (props) => (props.fill ? props.fill : "#FFF") };
    margin-bottom: 4px;
    transition: 0.3s;

    ${ (props) => props.container }:hover & {
        fill: ${ highlightColor };
    }
    // ${ (props) => props.container }:focus & {
    //     fill: ${ highlightColor };
    // }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const Divider = styled.div`
    width: 100%;
    position: relative;
    background: ${ (props) => props.centered ?
        `linear-gradient(to right, ${ highlightColor }00, ${ highlightColor }66, ${ highlightColor }00);` :
        `linear-gradient(to right, ${ highlightColor }66, ${ highlightColor }00);`
    }
    padding: 1px;
    box-sizing: border-box;
    ${ (props) => props.vertical && `
        transform: rotateZ(90deg);
    `}
`;

export const Text = styled.span`
    color: ${ (props) => props.color };
    font-size: inherit;
    ${ (props) => props.small && "font-size: 10px;" }
    ${ (props) => props.noSelect && "user-select: none;" }
`;

const inputFieldStyle = css`
    background-color: unset;
    border: none;
    border-bottom: solid 1px ${ dullWhite };
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    color: ${ bodyFontColor };
    font-family: inherit;
    font-size: inherit;
    transition: 0.1s;

    &:focus {
        outline: none;
        border-bottom: solid 2px ${ highlightColor };
    }
`;

export const InputField = styled.input`
    ${ inputFieldStyle }
    height: 48px;
`;

export const TextArea = styled.textarea`
    ${ inputFieldStyle }

    resize: vertical;
    min-height: 100px;
    margin-bottom: 8px;
    height: ${ (props) => props.height ? props.height : `100px` };
`;

export const Button = styled.button`
    border: none;
    background-color: inherit;
    cursor: pointer;
    color: inherit;
    outline: none;

    &:hover {
        color: ${ highlightColor };
    }
    &:active {
        outline: none;
        // transform: scale(${ animScaleSize });
    }
`;

const textButtonStyle = css`
    border: none;
    background-color: inherit;
    cursor: pointer;
    color: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    outline: none;
    transition: 0.3s;
    font-size: inherit;

    &:hover {
        color: ${ highlightColor };
        ${ (props) => !props.enableFocus && `
            border: none !important;
            outline: none !important;
        `}
    }
    &:active {
        outline: none;
        // transform: scale(${ animScaleSize });
        ${ (props) => !props.enableFocus && `
            border: none !important;
            outline: none !important;
        `}
    }
    &:focus {
        ${ (props) => props.enableFocus ? `
            transition: 0s;
            border-radius: 3px;
            border: solid 1px;
            color: ${ highlightColor };
        ` : `
            border: none !important;
            outline: none !important;
        `}
    }
`;

export const TextButton = styled.button`
    ${ textButtonStyle }
    padding: 0;
    font-family: inherit;
`;

export const Link = styled.a`
    ${ textButtonStyle }
    text-decoration: ${ (props) => props.underline ? `underline` : `none` }
`;

export const ClippedCircleBorder = styled.div`
    clip-path: circle(50% at 50% 50%);
    width: 98%;
    height: 98%;
    position: absolute;
    background: ${ highlightColor };
`;

export const ClippedCircle = styled.div`
    clip-path: circle(50% at 50% 50%);
    width: 94%;
    height: 94%;
    position: absolute;
`;

export const ProfilePicContainer = styled.div`
    width: ${ (props) => (props.width ? props.width : "100%") };
    height: ${ (props) => (props.height ? props.height : "100%") };
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

export const TwoColumnContainer = styled(RowContainer)`
    width: 100%;
    align-self: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const OneOfTwoColumn = styled.div`
    flex-grow: ${ (props) => props.mobile ? 1 : 2 };
    width: ${ (props) => props.mobile ? `100%` : `50%` };
    max-width: ${ (props) => props.mobile ? `100%` : `48%` };
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-bottom: 16px;
`;

export const AbsoluteMenuWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`;

export const ToTopButton = styled(Button)`
    position: fixed;
    bottom: 0;
    display: ${ (props) => { return props.visible ? `inline-block` : `none` } };
    ${ (props) => props.breakpoint === Breakpoint.Tablet && `right: 0;` }
    padding: 4px;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    background: #54555A;
    outline: none;
    border-radius: 3px;
    ${ (props) => props.breakpoint === Breakpoint.Tablet && `transform: translateX(-50%);` }
    margin-bottom: ${ (props) =>
        props.breakpoint === Breakpoint.Tablet ? `32px;` : `20px;`
    }
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
`;

const spin = keyframes`
    to {
        transform: rotate(360deg);
        transform-origin: center center;
    }
`;

export const Loader = styled.div`
    animation: ${ spin } 1s steps(8, end) infinite;
    display: flex;
    place-content: center;
    width: fit-content;
    height: fit-content;
    margin-left: 4px;
`;