import React, { useState, createContext, useRef } from "react";
import useAccessibility from "../hooks/useAccessibility";

export const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {
    const { enableOutline, setEnableOutline } = useAccessibility(false);

    const [pdf, setPDF] = useState("");
    const [profilePic, setProfilePic] = useState("");
    const [anchorToID, setAnchorToID] = useState("");
    const [breakpoint, setBreakpoint] = useState("")
    // The mouse position delta in relation to the contentScrollContainer
    // Used to animate the skyline
    const [skylineDelta, setSkylineDelta] = useState({ x: 0, y: 0 });
    const contentScrollContainer = useRef(null);

    const valueObject = {
        contentScrollContainer,
        pdf, setPDF,
        profilePic, setProfilePic,
        anchorToID, setAnchorToID,
        enableOutline, setEnableOutline,
        skylineDelta, setSkylineDelta,
        breakpoint, setBreakpoint,
    };

    return (
        <AppDataContext.Provider
            value={ valueObject }
        >
            {children }
        </AppDataContext.Provider>
    );
};
