// #C5462A
// #7CA29F
// #BB8B7A
// #ECE0AD      maybe
// #C05242      nice
// #657861
export const highlightColor = "#7AAA71";
export const bodyFontColor = "#DDD";
export const dullWhite = "#AAA";
export const errorRed = "#DF3A3A";
export const animScaleSize = 0.98;

export const Breakpoint = {
    // 640px wide and below
    Mobile: "(max-width: 640px)",
    // Between 640px and 1164px wide
    Tablet: "(max-width: 1163px) and (min-width: 641px)",
    // 1164px wide and above
    Desktop: "(min-width: 1164px)",
};