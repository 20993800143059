import styled, { keyframes, css } from "styled-components";

const animationLength = 28;
const fgMax = 20;
const mgMax = 10;
const bgMax = 5;

const pingpong = (range) => keyframes`
    0%, 100% {
        transform: translateX(0px);
        animation-timing-function:linear;
    }

    25% {
        transform: translateX(-${ range }px);
        animation-timing-function:linear;
    }

    75% {
        transform: translateX(${ range }px);
        animation-timing-function:linear;
    }
`;

const Image = css`
    width: 140vw;
    height: 100%;
    position: absolute;
    border-radius: 3px;
    background-image: url("${ (props) => props.url }");
    background-repeat: repeat-x;
    background-position: ${ (props) => props.position };
    ${ (props) => !props.desktop && `
        background-size: cover;
    `}
`;

export const FGImage = styled.div`
    ${ Image }
    z-index: -1000;
    ${ (props) => !props.desktop && css`
        animation: ${ pingpong(fgMax) } ${ animationLength }s infinite;
    `}
`;

export const MGImage = styled.div`
    ${ Image }
    z-index: -1100;
    ${ (props) => !props.desktop && css`
        animation: ${ pingpong(mgMax) } ${ animationLength }s infinite;
    `}
`;

export const BGImage = styled.div`
    ${ Image }
    z-index: -1200;
    ${ (props) => !props.desktop && css`
        animation: ${ pingpong(bgMax) } ${ animationLength }s infinite;
    `}
`;